import React from "react";
import { Button } from "@mui/material";
import styles from "./Home.module.css";
import CustomTimeline from "./CustomTimeline";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <div className={styles.container}>
        <Button
          className={styles.startButton}
          sx={{ color: "#fff" }}
          variant="contained"
        >
          Donate
        </Button>
      </div>
      <CustomTimeline></CustomTimeline>
    </>
  );
};
export default Home;
