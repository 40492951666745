import React from "react";
import {
  Button,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Input,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./Header.module.css";

const navItems = ["Home", "Donate", "About", "Contact"];

export interface IHeaderProps {}

export default function Header(props: IHeaderProps) {
  return (
    <AppBar component="nav">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Input
          placeholder="Search"
          className={styles.searchInput}
          sx={{ color: "#fff" }}
        ></Input>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        >
          Bliss of Giving
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) => (
            <Button key={item} sx={{ color: "#fff" }}>
              {item}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
