import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import ShareIcon from "@mui/icons-material/Share";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Typography from "@mui/material/Typography";

export default function CustomTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <VolunteerActivismIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "14px", px: 2 }}>
          <Typography variant="h6" component="span">
            Start
          </Typography>
          <Typography>A fundraiser idea!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="secondary">
            <ShareIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "14px", px: 2 }}>
          <Typography variant="h6" component="span">
            Share
          </Typography>
          <Typography>Tell a story!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="success" variant="outlined">
            <ConnectWithoutContactIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "14px", px: 2 }}>
          <Typography variant="h6" component="span">
            Withdraw
          </Typography>
          <Typography>For a cause!</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
