import React from "react";
import "./App.css";
import { Container, Box } from "@mui/material";
import Home from "./features/Home/Home";
import Footer from "./features/Footer/Footer";
import Header from "./features/Header/Header";

function App() {
  return (
    <div className="App">
      <Container>
        <Header></Header>
        <Box component="nav">
          <Home></Home>
          <Footer></Footer>
        </Box>
      </Container>
    </div>
  );
}

export default App;
